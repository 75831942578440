<template>
  <v-banner class="pibot-page-heading">
    <h1 class="text-h5">
      <slot />
    </h1>
    <small class="grey--text text--darken-1">
      <slot name="sub-title" />
    </small>
    <template #actions>
      <slot name="append" />
    </template>
  </v-banner>
</template>

<script>
export default {
  name: 'pibot-page-heading'
}
</script>

<style>
.pibot-page-heading .v-banner__wrapper {
  align-items: start !important;
}

.pibot-page-heading .v-banner__actions {
  align-items: end !important;
  margin-bottom: 0 !important;
}
</style>
